'use client';

import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { forwardRef } from 'react';

import styles from './ButtonContainer.module.css';
import Loading from './Loading';

export type LegacyButtonColor =
  | 'primary'
  | 'stonePrimary'
  | 'secondary'
  | 'ton'
  | 'ghost';

export interface ButtonProps {
  children: React.ReactNode;
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
  disabled?: boolean;
  color?: LegacyButtonColor;
  size?: 'regular' | 'large';
  className?: string;
  href?: string;
  hash?: string;
  query?: string;
  target?: string;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  isLoading?: boolean;
  isLinkDisabled?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      disabled,
      color = 'primary',
      size = 'large',
      className,
      href,
      hash,
      query,
      type = 'button',
      fullWidth = false,
      isLoading,
      isLinkDisabled = false,
      ...rest
    },
    ref,
  ) => {
    const router = useRouter();

    const queryRouter = query ? query : router?.query;

    const classStyle = classNames(
      styles.buttonContainer,
      styles[color],
      styles[size],
      fullWidth ? 'w-full' : 'w-full md:w-auto',
      className,
      { [styles.disabled]: isLinkDisabled || isLoading },
    );

    return href ? (
      <Link
        href={{ pathname: href, query: queryRouter, hash }}
        {...rest}
        className={classStyle}
      >
        <Loading isLoading={!!isLoading}>{children}</Loading>
      </Link>
    ) : (
      <button
        ref={ref}
        type={type}
        disabled={disabled || isLoading}
        {...rest}
        className={classStyle}
      >
        <Loading isLoading={!!isLoading}>{children}</Loading>
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
